import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const SiteInProgress = React.lazy(() => import("./components/pages/SiteInProgress"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const About = React.lazy(() => import("./components/pages/About"));
const Blogstandard = React.lazy(() => import("./components/pages/Blogstandard"));
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Portfoliogrid = React.lazy(() => import("./components/pages/Portfoliogrid"));
const Portfolioslider = React.lazy(() => import("./components/pages/Portfolioslider"));
const Portfoliomasonary = React.lazy(() => import("./components/pages/Portfoliomasonary"));
const Portfoliodetails = React.lazy(() => import("./components/pages/Portfoliodetails"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicedetails = React.lazy(() => import("./components/pages/Servicedetails"));
const Team = React.lazy(() => import("./components/pages/Team"));
const Teamdetails = React.lazy(() => import("./components/pages/Teamdetails"));
const Whyus = React.lazy(() => import("./components/pages/Whyus"));
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const TermsConditions = React.lazy(() => import("./components/pages/TermsConditions"));
const Cookies = React.lazy(() => import("./components/pages/Cookies"));
const Confidence = React.lazy(() => import("./components/pages/Confidence"));
const Park = React.lazy(() => import("./components/pages/Park"));
const Offers = React.lazy(() => import("./components/pages/ParkOffers"));
const RequestOffer = React.lazy(() => import("./components/pages/RequestOffer"));
const Error = React.lazy(() => import("./components/pages/Error"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Preloader/>
        <Switch>
          <Route exact path="/" component={SiteInProgress} />
          {/*<Route exact path="/home-v1" component={Home} />*/}
          {/*<Route exact path="/home-v2" component={Hometwo} />*/}
          {/*<Route exact path="/home-v3" component={Homethree} />*/}
          {/*<Route exact path="/about" component={About} />*/}
          {/*<Route exact path="/products" component={Portfoliogrid} />*/}
          {/*<Route exact path="/blog-standard" component={Blogstandard} />*/}
          {/*<Route exact path="/blog-grid" component={Bloggrid} />*/}
          {/*<Route exact path="/blog-details" component={Blogdetails} />*/}
          {/*<Route exact path="/portfolio-grid" component={Portfoliogrid} />*/}
          {/*<Route exact path="/portfolio-slider" component={Portfolioslider} />*/}
          {/*<Route exact path="/portfolio-masonary" component={Portfoliomasonary} />*/}
          {/*<Route exact path="/portfolio-details" component={Portfoliodetails} />*/}
          {/*<Route exact path="/services" component={Service} />*/}
          {/*<Route exact path="/service-details" component={Servicedetails} />*/}
          {/*<Route exact path="/team" component={Team} />*/}
          {/*<Route exact path="/team-details" component={Teamdetails} />*/}
          {/*<Route exact path="/why-us" component={Whyus} />*/}
          {/*<Route exact path="/faq" component={Faqs} />*/}
          {/*<Route exact path="/park" component={Park} />*/}
          {/*<Route exact path="/park/:pId/offers" component={Offers} />*/}
          {/*<Route exact path="/park/:pId/offers/:oId" component={RequestOffer} />*/}
          {/*<Route exact path="/contact" component={Contact} />*/}
          {/*<Route exact path="/terms" component={TermsConditions} />*/}
          {/*<Route exact path="/cookies" component={Cookies} />*/}
          {/*<Route exact path="/confidence" component={Confidence} />*/}
          {/*<Route exact path="/error-page" component={Error} />*/}
          {/*<Route exact component={Error} />*/}
          <Route exact component={SiteInProgress} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
